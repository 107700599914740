<template>
  <div class="device-manager-common-form-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      submitContentType="application/x-www-form-urlencoded;charset=UTF-8"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <template v-if="!isConmunityUser">
            <el-form-item label="所属公司" prop="regionId" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change'}]">
              <v-select2 :width="width" placeholder="所属公司" v-model="form.regionId" v-bind="regionIdSel2Params" />
            </el-form-item>
          </template>
          <el-form-item label="通用类别" prop="type" :rules="[{ required: true, message: '请选择通用类别', trigger: 'blur'}]">
            <v-select :width="width" v-model="form.type" :options="typeOpts" />
          </el-form-item>
          <el-form-item label="相关信息" :rules="[{ required: true, message: '请填写相关信息', trigger: 'blur'}]">
            <div v-for="(item, index) in form.data" class="input-group" :key="index">
              <el-form-item :prop="'data.'+index+'.content'" :rules="[{ required: true, message: '请填写内容', trigger: 'blur'}]">
                <v-input :maxlength="300" class="input-content" placeholder="内容" v-model="item.content" />
              </el-form-item>
              <el-form-item v-if="addressVisible" :prop="'data.'+index+'.address'" :rules="[{ required: true, message: '请填写'+placeholder, trigger: 'blur'}]">
                <v-input :placeholder="placeholder" v-model="item.address" />
              </el-form-item>
              <el-form-item v-if="contentVisible" :prop="'data.'+index+'.contact'" :rules="[{ required: true, message: '请填写负责人姓名', trigger: 'blur'}]">
                <v-input  placeholder="负责人姓名" v-model="item.contact" />
              </el-form-item>
              <el-form-item v-if="contentVisible" :prop="'data.'+index+'.phone'" :rules="[{ required: true, message: '请填写联系电话', trigger: 'blur'},{ validator: validatePhone, trigger: 'blur' }]">
                <v-input placeholder="联系电话" v-model="item.phone" />
              </el-form-item>
              <v-button v-if="index" type="danger" text="删除" @click="remove(index)" />
            </div>
            <div class="btn-group">
              <v-button text="添加+" @click="add" />
            </div>
          </el-form-item>
          <el-form-item label="备注">
            <v-textarea v-model="form.remarks" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { typeOpts } from './map'
import { getCommonDetail, saveCommonURL } from './api'
import { regionParams } from 'common/select2Params'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { mobileRegular, telephoneRegular } from 'common/regular'

export default {
  name: 'DeviceConfigCommonForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      width: 182,
      queryUrl: getCommonDetail,
      submitUrl: saveCommonURL,
      form: {
        type: 1,
        data: [{}],
        remarks: ''
      },
      regionIdSel2Params: regionParams,
      typeOpts: typeOpts.filter(item => item.value)
    }
  },
  computed: {
    refuseReasonVisible () {
      return this.form.isExamine === 2
    },
    placeholder () {
      let placeholder = ''
      if (this.form.type === 3) {
        placeholder = '单位地址'
      } else if (this.form.type === 5) {
        placeholder = '单位'
      }
      return placeholder
    },
    contentVisible () {
      let type = this.form.type
      return type === 3
    },
    addressVisible () {
      let type = this.form.type
      return type === 3 || type === 5
    },
    isConmunityUser () {
      const userType = String(this.$store.state.userInfo.userType)
      return userType === '101' || userType === '102'
    }
  },
  mounted () {
    this.$setBreadcrumb('新增')
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      if (!regExp.test(value) && !connectionRegExp.test(value)) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    add () {
      this.form.data.push({})
    },
    remove (index) {
      this.form.data.splice(index, 1)
    },
    submitBefore (data) {
      data.data = JSON.stringify(data.data)
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item {
    text-align: left;
  }
</style>
<style lang="scss">
.device-manager-common-form-container {
  .el-input {
    margin-right: 15px;
  }
  .input-group {
    display: flex;
    margin-bottom: 25px;
  }
  .input-content {
    .el-input {
      width: 300px !important;
    }
  }
  .btn-group {
    margin-top: 15px;
  }
}
</style>
