var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-manager-common-form-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitBefore: _vm.submitBefore,
            submitContentType:
              "application/x-www-form-urlencoded;charset=UTF-8",
          },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  !_vm.isConmunityUser
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "所属公司",
                              prop: "regionId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择所属公司",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: {
                                    width: _vm.width,
                                    placeholder: "所属公司",
                                  },
                                  model: {
                                    value: _vm.form.regionId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "regionId", $$v)
                                    },
                                    expression: "form.regionId",
                                  },
                                },
                                "v-select2",
                                _vm.regionIdSel2Params,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "通用类别",
                        prop: "type",
                        rules: [
                          {
                            required: true,
                            message: "请选择通用类别",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { width: _vm.width, options: _vm.typeOpts },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "相关信息",
                        rules: [
                          {
                            required: true,
                            message: "请填写相关信息",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm._l(_vm.form.data, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "input-group" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "data." + index + ".content",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请填写内容",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  staticClass: "input-content",
                                  attrs: {
                                    maxlength: 300,
                                    placeholder: "内容",
                                  },
                                  model: {
                                    value: item.content,
                                    callback: function ($$v) {
                                      _vm.$set(item, "content", $$v)
                                    },
                                    expression: "item.content",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.addressVisible
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "data." + index + ".address",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请填写" + _vm.placeholder,
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-input", {
                                      attrs: { placeholder: _vm.placeholder },
                                      model: {
                                        value: item.address,
                                        callback: function ($$v) {
                                          _vm.$set(item, "address", $$v)
                                        },
                                        expression: "item.address",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.contentVisible
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "data." + index + ".contact",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请填写负责人姓名",
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-input", {
                                      attrs: { placeholder: "负责人姓名" },
                                      model: {
                                        value: item.contact,
                                        callback: function ($$v) {
                                          _vm.$set(item, "contact", $$v)
                                        },
                                        expression: "item.contact",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.contentVisible
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "data." + index + ".phone",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请填写联系电话",
                                          trigger: "blur",
                                        },
                                        {
                                          validator: _vm.validatePhone,
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-input", {
                                      attrs: { placeholder: "联系电话" },
                                      model: {
                                        value: item.phone,
                                        callback: function ($$v) {
                                          _vm.$set(item, "phone", $$v)
                                        },
                                        expression: "item.phone",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            index
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c("v-button", {
                            attrs: { text: "添加+" },
                            on: { click: _vm.add },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-textarea", {
                        model: {
                          value: _vm.form.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }